'use client';

//Libraries
import {
    usePathname
    , useRouter
} from 'next/navigation';
import {
    useEffect
    , useState
} from 'react';

//Ducks
import { useSelector } from 'react-redux';

//Components
import LoginLayout from '@/layouts/LoginLayout/LoginLayout';

//Types
import { StoreState } from '@/ducks/index';
import pageConfig, { PageConfig } from './config';

//Utils
import { pxrem } from '@/utils/pxrem';

//MUI
import {
    Stack
    , SxProps
    , Theme
    , Typography
} from '@mui/material';

export const NotFoundModule = () => {
    const isUserLoggedIn = useSelector<StoreState>( state => state.user.loggedIn );
    const [ pathname, setPathname ] = useState<string>( '' );
    const [ heading, setHeading ] = useState<string>( pageConfig[ '/maintenance' ].heading );
    const [ text, setText ] = useState<string>( pageConfig[ '/maintenance' ].text );
    const router = useRouter();
    const routerPathname = usePathname();

    useEffect( () => {
        setPathname( routerPathname || '' );
        const config = pageConfig[ routerPathname as keyof PageConfig ]
            ? pageConfig[ routerPathname as keyof PageConfig ]
            : pageConfig[ '/404' ];
        setHeading( config.heading );
        setText( config.text );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ routerPathname ] );

    useEffect( () => {
        const interval = setInterval( () => {
            if (
                process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'false'
                && pathname === '/maintenance'
                && router
            ) {
                router.push( '/' );
            }
        }, 3000 );
        return () => {
            clearInterval( interval );
        };
        // eslint-disable-next-line
    }, [ pathname ] );

    const actionTextStyling: SxProps<Theme> = {
        cursor: 'pointer'
        , color: theme => theme.palette.primary.main
        , '&:hover': {
            textDecoration: 'underline'
        }
    };

    const renderText = () => {
        if ( pathname === '/maintenance' ) {
            return null;
        } else if ( pathname === '/unauthorized' && history.length > 2 ) {
            sessionStorage.removeItem( 'fromRoute' );
            return (
                <Typography
                    sx={ actionTextStyling }
                    onClick={ () => router.back() }
                >
                    Return to previous page
                </Typography>
            );
        } else if ( isUserLoggedIn ) {
            return (
                <Typography
                    sx={ actionTextStyling }
                    onClick={ () => router.push( '/company/dashboard/ops-summary' ) }
                >
                    Go to Dashboard
                </Typography>
            );
        } else {
            return (
                <Typography
                    sx={ actionTextStyling }
                    onClick={ () => router.push( '/login' ) }
                >
                    Go to Login
                </Typography>
            );
        }
    };

    return (
        <LoginLayout
            hideBackground
            showOperatorInfo={ false }
        >
            <Stack
                spacing={ 2 }
                sx={ {
                    justifyContent: 'center'
                    , alignItems: 'center'
                    , textAlign: 'center'
                } }
            >
                <Typography
                    variant='h1'
                    sx={ {
                        fontSize: {
                            sm: pxrem( 18 )
                            , md: pxrem( 34 )
                        }
                        , mt: pxrem( 50 )
                    } }
                >
                    { heading }
                </Typography>
                <Typography sx={ { fontSize: pxrem( 18 ) } }>
                    { text }
                </Typography>
                { renderText() }
            </Stack>
        </LoginLayout>
    );
};
