interface RouteConfig {
    heading: string;
    text: string;
}

export interface PageConfig {
    '/maintenance': RouteConfig;
    '/404': RouteConfig;
    '/unauthorized': RouteConfig;
    '/hidden-on-mobile': RouteConfig;
}

const pageConfig: PageConfig = {
    '/maintenance': {
        heading: 'Sorry! The site is temporarily down for maintenance.'
        , text: 'We apologize for the inconvenience. The site should be back up soon.'
    }
    , '/404': {
        heading: 'Sorry! The page you are looking for does not exist.'
        , text: 'Please check your url and try again.'
    }
    , '/unauthorized': {
        heading: 'Sorry! You are not authorized to view this page.'
        , text: 'Please contact your system administrator if you believe this to be an error.'
    }
    , '/hidden-on-mobile': {
        heading: 'Sorry! This page is not viewable on small screens.'
        , text: 'Please switch to a larger screen to view this page.'
    }
};

export default pageConfig;
